jQuery(document).ready(function($) {
  require('./components/mobile-header.js');
  require('./components/accordion.js');
  require('./components/slider.js');


  jQuery(window).on('scroll', function() {
    if (1 < window.pageYOffset) {
      jQuery('header').addClass('scroll-active');
    } else {
      jQuery('header').removeClass('scroll-active');
    }
  });
});

// Calculate Header Height
var URL = window.location.href;

if(URL != 'https://farnorth.c.thrivex.io/') {
  var box = document.querySelector('.header');
  var height = box.offsetHeight;
  document.querySelector('.header-height').style.marginTop = height + 'px';

  window.addEventListener('resize', resizeFunction);
  function resizeFunction() {
    var box2 = document.querySelector('.header');
    var height2 = box2.offsetHeight;
    document.querySelector('.header-height').style.marginTop = height2 + 'px';
  }
}